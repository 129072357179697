<template>
  <div class="form-page">
    <error :errMsg="formMsg" v-if="!isPreview && formError" />
    <div v-else>
      <div>
        <div v-if="!showSuccessPage && showFormPage">
          <div v-if="(formData && formData.publishState) || (formData && isPreview)">
            <div class="content-wrap" :style="{ background: `url(${backgroudImg})` }">
              <div class="title-wrap">
                <span class="title">{{ formData.formName }}</span>
                <span class="desc">{{ formData.description }}</span>
              </div>
              <div class="form-wrap" v-if="formData.options">
                <div class="form-item" v-for="(item, index) in formData.options" :key="item.id">
                  <!-- 单行文本 -->
                  <template v-if="item.optionType === 1">
                    <span class="label" :class="{ required: item.required }">{{ item.optionName }}</span>
                    <div class="cube-input">
                      <input v-model="form[item.id]" class="input-box" type="text" :placeholder="`请输入${item.optionName}`" maxlength="40" />
                    </div>
                  </template>
                  <!-- 多行文本 -->
                  <template v-if="item.optionType === 2">
                    <span class="label" :class="{ required: item.required }">{{ item.optionName }}</span>
                    <div class="cube-input">
                      <textarea v-model="form[item.id]" class="input-box" rows="3" cols="20" :placeholder="`请输入${item.optionName}`" maxlength="200"></textarea>
                    </div>
                  </template>
                  <!-- 单选 -->
                  <template v-if="item.optionType === 3">
                    <span class="label" :class="{ required: item.required }">{{ item.optionName }}</span>
                    <div class="cube-input">
                      <input v-model="formData[item.id]" class="input-box radio-input" type="text" readonly @click="handleFormItemClick(index, 'showRadioPicker', item)" :placeholder="`请选择${item.optionName}`" />
                      <template v-if="currentClickItemId === item.id">
                        <van-popup v-model="showRadioPicker" class="form-van-popup" get-container="body" round position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
                          <van-radio-group v-model="listRadio[item.id]">
                            <van-cell-group>
                              <div v-for="e in item.optionDataList" :key="e.id" class="check-box">
                                <van-cell :title="e.optionValue" clickable>
                                  <template #right-icon>
                                    <van-radio :name="e" />
                                  </template>
                                </van-cell>
                                <div class="img-box" v-if="e.optionValueImg">
                                  <img :src="e.optionValueImg" alt />
                                </div>
                              </div>
                            </van-cell-group>
                          </van-radio-group>
                          <div class="botton-wrap">
                            <van-button class="cancel" @click="cancelRadio(listRadio[item.id])">取消</van-button>
                            <van-button class="confirm" @click="confirmRadio(listRadio[item.id])">确定</van-button>
                          </div>
                        </van-popup>
                      </template>
                    </div>
                  </template>

                  <!-- 多选 -->
                  <template v-if="item.optionType === 4">
                    <span class="label" :class="{ required: item.required }">{{ item.optionName }}</span>
                    <div class="cube-input">
                      <input v-model="formData[item.id]" class="input-box radio-input" type="text" readonly @click.stop.prevent="handleFormItemClick(index, 'showCheckPicker', item)" :placeholder="`请选择${item.optionName}`" />
                    </div>
                    <template v-if="currentClickItemId === item.id">
                      <van-popup v-model="showCheckPicker" class="form-van-popup" get-container="body" round position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
                        <van-checkbox-group v-model="list[item.id]">
                          <van-cell-group>
                            <div class="check-box" v-for="e in item.optionDataList" :key="e.id">
                              <van-cell clickable :title="e.optionValue">
                                <template #right-icon>
                                  <van-checkbox :name="e" ref="checkboxes" />
                                </template>
                              </van-cell>
                              <div class="img-box" v-if="e.optionValueImg">
                                <img :src="e.optionValueImg" alt />
                              </div>
                            </div>
                          </van-cell-group>
                        </van-checkbox-group>
                        <div class="botton-wrap">
                          <van-button class="cancel" @click="cancelCheck(list[item.id])">取消</van-button>
                          <van-button class="confirm" @click="confirmCheck(list[item.id])">确定</van-button>
                        </div>
                      </van-popup>
                    </template>
                  </template>

                  <!-- 姓名 -->
                  <template v-if="item.optionType === 5">
                    <span class="label" :class="{ required: item.required }">{{ item.optionName }}</span>
                    <div class="cube-input">
                      <input v-model="form[item.id]" class="input-box" type="text" :placeholder="`请输入${item.optionName}`" maxlength="200" />
                    </div>
                  </template>

                  <!-- 手机号 -->
                  <template v-if="item.optionType === 6">
                    <span class="label" :class="{ required: item.required }">{{ item.optionName }}</span>
                    <div class="cube-input">
                      <input v-model="form[item.id]" class="input-box" type="text" :placeholder="`请输入${item.optionName}`" oninput="value=value.replace(/[^0-9.]/g, '')" />
                    </div>
                  </template>
                </div>
              </div>
              <!-- 名片 -->
              <template v-if="formData.hasCard && cardId">
                <card :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="'form'"></card>
              </template>
            </div>
            <!-- 聊一聊 -->
            <template v-if="formData.hasCard && cardId">
              <fixed-button :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="'form'"></fixed-button>
            </template>
            <footer class="footer">
              <van-button @click="onSubmit" :disabled="hideSubmitBtn">提交</van-button>
            </footer>
          </div>
        </div>
      </div>
      <!--  表单详情  -->
      <template v-if="!showSuccessPage && isDetail">
        <div v-if="detailData" class="detail-page">
          <div class="content-wrap" :style="{ background: `url(${backgroudImg})` }">
            <div class="title-wrap">
              <span class="title">{{ detailData.formName }}</span>
              <div class="info">
                <span>{{ detailData.nickname }}</span>
                <span>{{ detailData.submitTime }}</span>
              </div>
            </div>
            <div class="form-wrap" v-if="detailData.options">
              <div v-for="item in detailData.options">
                <!-- 单选 多选 -->
                <template v-if="item.optionType === 3 || item.optionType === 4">
                  <template v-if="item.optionDataList.length === 1">
                    <template v-for="e in item.optionDataList">
                      <van-cell class="has-img" v-if="e.optionValueImg" :title="item.optionName" :value="e.optionValue" @click="getImg(e.optionValueImg)" />
                      <van-cell v-else :title="item.optionName" :value="e.optionValue" />
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="e in item.optionDataList">
                      <van-cell class="has-img" v-if="e.optionValueImg" :title="item.optionName" :value="e.optionValue" @click="getImg(e.optionValueImg)" />
                      <van-cell v-else :title="item.optionName" :value="e.optionValue" />
                    </template>
                  </template>
                </template>
                <template v-else>
                  <van-cell :title="item.optionName" :value="item.submitValue" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="success-page">
          <h2>暂无数据</h2>
        </div>
      </template>
      <div v-if="showSuccessPage" class="success-page">
        <h2>提交成功</h2>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/api/form';
import Common from '@/api/common';
import wxFn from '@/util/wxFn';
import trackFn from '@/util/trackFn';
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      paramsData: {}, // 存放接口参数
      form: {},
      radio: '',
      formData: {},
      showRadioPicker: false,
      showCheckPicker: false,
      showSuccessPage: false,
      hideSubmitBtn: false,
      isDetail: false,
      currentClickItemId: '',
      list: {},
      listRadio: {},
      tempList: [],
      backgroudImg: '',
      isPreview: false,
      cmeId: '',
      cardId: '',
      extField: '',
      cardInfo: {},
      showFormPage: false,
      trackDataTemp: {
        durationTimes: null
      },
      detailData: {},
      formError: false,
      formMsg: '该表单已关闭',
      trackData: {}
    };
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  mounted() {
    const cmeId = this.$route.query.cmeId;
    const from = this.$route.query.from;
    if (cmeId) {
      document.title = '表单';
      switch (from) {
        case 'draft':
          this.getDraftData(cmeId);
          this.hideSubmitBtn = true;
          this.isPreview = true;
          break;
        case 'preview':
          this.getPreviewData(cmeId);
          this.hideSubmitBtn = true;
          this.isPreview = true;
          break;
        case 'detail':
          this.getDetailData(cmeId);
          break;
        default:
          this.queryData(cmeId);
          break;
      }
    }
  },
  methods: {
    getImg(images) {
      const arr = [];
      arr.push(images);
      ImagePreview({
        images: arr,
        showIndex: true,
        loop: false
        // startPosition: index
      });
    },
    async onSubmit() {
      let requiredList = [];
      let phoneFlag = true;
      let arr = this.formData.options.map(item => {
        let ids = [];
        if (this.list[item.id] && this.list[item.id].length) {
          ids = this.list[item.id].map(e => e.id);
        }
        switch (item.optionType) {
          case 3:
            if (item.required) {
              requiredList.push(!!this.listRadio[item.id]);
            }
            return {
              optionId: item.id,
              optionType: item.optionType,
              optionDataId: this.listRadio[item.id] ? this.listRadio[item.id].id : ''
            };
          case 4:
            if (item.required) {
              requiredList.push(!!this.list[item.id]);
            }
            return {
              optionId: item.id,
              optionType: item.optionType,
              optionDataId: ids.length ? ids.join(',') : ''
            };
          default:
            if (item.optionType === 6) {
              if (this.form[item.id] && !this.testPhone(this.form[item.id])) {
                this.$toast('请输入正确的手机号');
                phoneFlag = false;
              }
            }
            if (item.required) {
              requiredList.push(!!this.form[item.id]);
            }
            return {
              optionId: item.id,
              submitValue: this.form[item.id] ? this.form[item.id] : '',
              optionType: item.optionType
            };
        }
      });
      const paramsData = this.paramsData.params;
      if (!phoneFlag) {
        return false;
      }
      if (requiredList.includes(false)) {
        this.$toast('请填入必填项');
        return false;
      }
      const sendParams = {
        formId: this.formData.id,
        options: arr,
        shareTraceId: paramsData.shareTraceId,
        extField: JSON.stringify(paramsData),
        url: paramsData.url,
        type: paramsData.type,
        shareId: paramsData.shareId
      };
      try {
        await Form.submitFormData(sendParams);
        this.$toast('提交成功');
        this.showSuccessPage = true;

        // 触发埋点 提交表单
        trackFn.formSubmitTrace(this.formData);
      } catch (error) {
        console.log(error.msg);
        this.$toast(error.msg || '网络错误');
      }
    },
    async queryData(id) {
      try {
        console.log('this.paramsData', this.paramsData);
        this.formData = await Form.getFormData({ formId: id });
        await this.initWx();
        if (this.formData.options && this.formData.options.length) {
          for (let i in this.formData.options) {
            console.log('xxxx', this.formData.options[i]);
            // 若为手机号类型输入框，则填入接口返回的手机号
            const item = this.formData.options[i];
            if (item.optionType === 6 && this.paramsData.user && this.paramsData.user.phone) {
              this.form[item.id] = this.paramsData.user.phone;
            }
          }
        }
        this.backgroudImg = this.formData && this.formData.backgroundImg ? this.formData.backgroundImg : '';
      } catch (error) {
        console.error('queryData', error);
        if (error.code && error.code === 'C31010') {
          this.formError = true;
          this.formMsg = '该表单已关闭';
        } else if (error.code && error.code === 'C31019') {
          this.formError = true;
          this.formMsg = '该表单已删除';
        } else {
          this.$toast(error.msg || '网络错误');
        }
      }
    },
    async initWx() {
      try {
        const wxData = await wxFn.init(['onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocation', 'getNetworkType']);
        // 这里用接口返回的数据
        let paramsData = wxData;
        this.paramsData = wxData;
        this.cardId = paramsData.params.saleId ? paramsData.params.saleId : '';
        this.cmeId = paramsData.params.cmeId;
        this.extField = JSON.stringify(paramsData.params);
        if (this.cardId) {
          this.getInfo();
        } else {
          this.showFormPage = true;
        }
        wxFn.initWxShareShare(paramsData, this.formData.shareName, this.formData.digest, this.formData.coverImg, 'form', this.formData);
        // 触发埋点 浏览
        this.trackData = { ...this.formData, ...this.trackDataTemp };
        // 浏览表单IN 触发IN事件埋点
        // trackFn.formViewTrace(paramsData, this.trackData, 'IN');
        // 浏览内容IN（大米要求必传的事件）
        trackFn.contentViewTrace(paramsData, this.trackData, 'IN', 'form');

        // 浏览表单OUT 注册OUT事件埋点（注册事件，sdk进行浏览器关闭事件上报 ）
        // trackFn.viewOutTrace('form_read', paramsData, {
        //   form_id: trackData.id,
        //   form_title: trackData.formName,
        // });
        // trackFn.contentOutViewTrace(paramsData, this.trackData, 'form');
      } catch (error) {
        console.error('error', error.msg);
        this.$toast(error.msg || '网络错误');
      }
    },
    // 获取草稿数据
    async getDraftData(id) {
      try {
        const { content } = await Common.getPreviewContent({ id });
        this.formData = content;
        this.backgroudImg = this.formData.backgroundImg ? this.formData.backgroundImg : '';
        this.showFormPage = true;
      } catch (error) {
        console.error('error', error);
        this.$toast(error.msg || '网络错误');
      }
    },
    // 获取预览数据
    async getPreviewData(id) {
      try {
        this.formData = await Form.getFormData({ formId: id });
        this.backgroudImg = this.formData && this.formData.backgroundImg ? this.formData.backgroundImg : '';
        this.showFormPage = true;
      } catch (error) {
        console.error('error', error);
        this.$toast(error.msg || '网络错误');
      }
    },
    // 获取表单提交详情
    async getDetailData(id) {
      try {
        const unionId = this.$route.query.unionId;
        const sendParams = {
          formId: id,
          unionId: unionId
        };
        this.detailData = await Form.getFormInfo(sendParams);
        this.backgroudImg = this.detailData && this.detailData.backgroundImg ? this.detailData.backgroundImg : '';

        this.showFormPage = false;
        this.isDetail = true;
      } catch (e) {
        console.log(e.msg);
      }
    },
    // 获取名片信息
    async getInfo() {
      try {
        const params = {
          cardId: this.cardId,
          cmeId: this.cmeId,
          extField: this.extField
        };
        this.cardInfo = await Common.getCardInfo(params);
        this.$store.commit('app/SET_CARD_DATA', this.cardInfo);
        this.showFormPage = true;
      } catch (error) {
        this.$toast(error.msg || '请求异常');
      }
    },
    handleFormItemClick(index, type) {
      this.list[this.currentClickItemId] = this.tempList[this.currentClickItemId];
      this.listRadio[this.currentClickItemId] = this.tempList[this.currentClickItemId];
      this.currentClickItemId = this.formData.options[index].id;
      this[type] = true;
    },
    // 多选确认选择
    confirmCheck(data) {
      if (data && data.length) {
        this.formData[this.currentClickItemId] = data.map(e => {
          return e.optionValue;
        });
        this.tempList[this.currentClickItemId] = data;
        this.list[this.currentClickItemId] = data.map(e => e);
      } else {
        this.formData[this.currentClickItemId] = [];
        this.tempList[this.currentClickItemId] = [];
      }
      this.showCheckPicker = false;
    },
    cancelCheck() {
      this.showCheckPicker = false;
    },
    // 单选确认选择
    confirmRadio(data) {
      if (data) {
        this.formData[this.currentClickItemId] = data.optionValue;
        this.tempList[this.currentClickItemId] = data;
        this.listRadio[this.currentClickItemId] = data;
      }
      this.showRadioPicker = false;
    },
    cancelRadio() {
      this.showRadioPicker = false;
    },
    // 手机号验证
    testPhone(str) {
      const reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/;
      return reg.test(str);
    }
  }
};
</script>

<style lang="less">
html,
body {
  height: 100%;
}
input,
textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}
input,
textarea {
  -webkit-appearance: none;
}
.form-page {
  background-color: #f7f9ff;

  display: flex;
  flex-direction: column;
  height: 100%;
  .content-wrap {
    padding: 12px 20px 30px;
    overflow: auto;
    height: calc(100vh - 120px);
    margin-bottom: 88px;
    background-size: cover;
    background-repeat: no-repeat;

    .title-wrap {
      padding: 16px;
      background: #ffffff;
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.8);
      box-shadow: 1px 1px 4px 2px rgba(171, 171, 171, 0.15);
      border-radius: 4px;
      word-break: break-all;
      span {
        display: block;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .desc {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .form-wrap {
      background-color: #ffffff;
      padding: 16px;
      box-shadow: 1px 1px 4px 2px rgba(171, 171, 171, 0.15);
      border-radius: 4px;
      margin-bottom: 16px;
      .form-item {
        margin-bottom: 16px;
      }
      .cube-input {
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -webkit-user-select: text !important;
        align-items: center;
        font-size: 14px;
        line-height: 1.429;
        background-color: #fff;
        position: relative;
        .input-box {
          display: block;
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          flex: 1;
          width: 100%;
          min-width: 0;
          padding: 8px;
          box-sizing: border-box;
          color: #606266;
          border-radius: 2px;
          border: 1px solid #d9e1ec;
          outline: none;
          font-size: 12px;
          &:active,
          &:hover {
            border: 1px solid #4d78ff;
          }
        }
        .radio-input {
          background: url('../../assets/icons/img/arrow.png') no-repeat 98% center;
        }
      }
      .label {
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        margin-bottom: 8px;
        display: block;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 12px 0 32px;
    width: 100%;
    z-index: 999;

    background-color: #ffffff;
    .van-button {
      background: #4d78ff;
      border-radius: 4px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      width: 90%;
    }
  }
  .label {
    position: relative;
    &.required::before {
      position: absolute;
      left: -10px;
      color: #ee0a24;
      font-size: 14px;
      content: '*';
    }
  }
  .success-page {
    height: calc(100vh - 46px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detail-page {
    .info {
      display: flex;
      justify-content: space-between;
    }
    .van-cell::after {
      border-bottom: none;
    }
    .has-img {
      .van-cell__value {
        text-decoration: underline;
        color: #4d78ff;
        cursor: pointer;
      }
    }
  }
}
.form-van-popup {
  max-height: 576px;
  padding: 16px 0;
  .van-radio-group,
  .van-checkbox-group {
    padding: 0 20px;
  }
  .check-box {
    box-shadow: 0px -1px 0px 0px #f4f4f4;
    padding-bottom: 8px;
    .van-cell {
      padding: 8px 0;
      &::after {
        border-bottom: none;
      }
      .van-cell__title {
        span {
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
        }
      }
    }
    .van-cell--clickable:active {
      background-color: #ffffff;
    }
    .img-box {
      img {
        width: 68px;
        height: 68px;
      }
    }
  }
  .van-radio__icon--checked .van-icon,
  .van-checkbox__icon--checked .van-icon {
    background-color: #4d78ff;
    border-color: #4d78ff;
  }
  .botton-wrap {
    margin: 24px 20px 16px;
    .van-button {
      background: #4d78ff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
    }
    .confirm {
      color: #ffffff;
      width: 186px;
      margin-left: 8px;
    }
    .cancel {
      background: rgba(77, 120, 255, 0.2);
      color: #4d78ff;
      width: 141px;
    }
  }
}
</style>
