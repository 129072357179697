import request from '../../util/request';

export default {
  // 获取表单内容
  getFormData(data) {
    return request.get({
      url: `/bff/martech-cme/form/v1/query-from`,
      params: { formId: data.formId }
    });
  },

  // 提交表单数据
  submitFormData(data) {
    return request.post(
      {
        url: '/bff/martech-cme/form/v1/submit-form-data',
        data
      });
  },

  // 获取表单详情
  getFormInfo(params) {
    return request.get(
      {
        url: `/bff/martech-cme/form/v1/customer-form-data`,
        params
      });
  }
};
